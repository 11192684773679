<div class="apartments">
  <app-top-header title="APARTMENTS" brad="SEARCH BY FEATURES"></app-top-header>

  <app-space></app-space>

  <div class="container">
    <div class="row mx-0">
      <div class="col-md-3">
        <mat-form-field appearance="fill">
          <mat-label>Unit type</mat-label>
          <mat-select (selectionChange)="filterDataByType($event.value)" [(ngModel)]="selectedType">
            <mat-option value="">
              Any
            </mat-option>
            <mat-option *ngFor="let type of types" [value]="type.id">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="fill">
          <mat-label>No. of bedrooms</mat-label>
          <mat-select (selectionChange)="getData(selectedType,$event.value)" [(ngModel)]="selectedRoom">
            <mat-option value="">
              Any
            </mat-option>
            <mat-option value="1">
              1
            </mat-option>
            <mat-option value="2">
              2
            </mat-option>
            <mat-option value="3">
              3
            </mat-option>
            <mat-option value="4">
              4
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="example-full-width" appearance="fill" [matMenuTriggerFor]="area">
          <mat-label>Area</mat-label>
          <input matInput placeholder="Ex. Pizza" value="{{square_area_gte}} to {{square_area_lte}}" disabled>
        </mat-form-field>

        <mat-menu #area="matMenu">
          <ngx-slider [(value)]="square_area_gte" [(highValue)]="square_area_lte" [options]="optionsArea"
            (userChangeEnd)="filterDataByArea($event)"></ngx-slider>
        </mat-menu>
      </div>
      <div class="col-md-3">

        <mat-form-field class="example-full-width" appearance="fill" [matMenuTriggerFor]="menu">
          <mat-label>Budget</mat-label>
          <input matInput placeholder="Budget" value="{{price_gte}} to {{price_lte}}" disabled>
        </mat-form-field>

        <mat-menu #menu="matMenu">
          <ngx-slider [(value)]="price_gte" [(highValue)]="price_lte" [options]="optionsPrice"
            (userChangeEnd)="filterDataByPrice($event)"></ngx-slider>
        </mat-menu>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="fill">
          <mat-label>Down Payment</mat-label>
          <mat-select #down_payment>
            <mat-option value="">
              Any
            </mat-option>
            <mat-option value="10%">
              10%
            </mat-option>
            <mat-option value="15%">
              15%
            </mat-option>
            <mat-option value="20%">
              20%
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="fill">
          <mat-label>Delivery Payment</mat-label>
          <mat-select [(value)]="down_payment.value">
            <mat-option value="">
              Any
            </mat-option>
            <mat-option value="10%">
              10%
            </mat-option>
            
            <mat-option value="15%">
              10%
            </mat-option>
            
            <mat-option value="20%">
              10%
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="fill">
          <mat-label>No. of Years</mat-label>
          <mat-select [(value)]="down_payment.value">
            <mat-option value="">
              Any
            </mat-option>
            <mat-option value="10%">
              8
            </mat-option>
            <mat-option value="15%">
              9
            </mat-option>
            <mat-option value="20%">
              10
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <app-space></app-space>


  <section class="py-5">

    <div class="container">
      <div class="row mx-0">
        <div class="col-md-3 count">{{data?.length}} UNIT/S FOUND</div>
        <div class="col-md-6 reset" (click)="reset()">RESET FILTER</div>
        <mat-form-field appearance="fill">
          <mat-label>Sort</mat-label>
          <mat-select (selectionChange)="sortData($event)" [(ngModel)]="sort">
            <mat-option value="price">
              Ascending price
            </mat-option>
            <mat-option value="-price">
              Descending price
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <app-space></app-space>

      <div class="table-responsive">
        <table class="table">
          <tbody>
            <tr *ngFor="let item of data">
              <!-- <th class="rate">4.6</th> -->
              <td (click)="openDialog(item)">
                <img [src]="item.sketch" width="100" alt="{{item.name}}" />
              </td>
              <td>
                <div>
                  <p>{{item.square_area}} M2</p>
                  <p>{{item.rooms}} ROOMS</p>
                  <p class="location">HOUSING {{item.housing}}</p>
                  <p class="location">FLOOR {{item.floor}}</p>
                </div>
              </td>
              <td>
                <div class="row justify-content-end">
                  <p class="mr-1">{{item.price}} EGP</p>
                  <!-- <img src="assets/Images/icons/heart.svg" width="25" /> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <app-space></app-space>
  </section>

  <app-make-your-choice></app-make-your-choice>
</div>