import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home/home.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  pageHeight;
  pageWidth;

  data:any = {}
  videoLink:any = ''
  isVideoShowen:any = false;

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    navSpeed: 700,
    // navText: ['<img src="assets/Images/circleArrowLeft.png" class="mr-1 ml-4" />', '<img src="assets/Images/circleArrowRight.png" class="mr-1 ml-4" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
  }

  faCustomOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
  }
  constructor(
    private _HomeService:HomeService,
    private sanitizer: DomSanitizer
  ) {
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('home').subscribe(res=>{
      this.data = res
    },err=>{
      console.log(err);
    })
    this._HomeService.get('config').subscribe((res:any)=>{
      this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(res.youtube)
      console.log(this.videoLink)
    },err=>{
      console.log(err);
    })
  }

  toggleVideoShowen(){
    this.isVideoShowen = !this.isVideoShowen
  }
}
