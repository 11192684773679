import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home/home.service';
import { SideNavService } from 'src/app/services/SideNav/side-nav.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  contactForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _HomeService: HomeService,
    private sidenav: SideNavService,
    private router:Router) {
    this.contactForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
      name: new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
    })
  }

  ngOnInit(): void {
  }


  aboutSideNav() {
    this.sidenav.toggle();
  }

  contactUs(values) {
    let data = { ...values }
    this._HomeService.set('call_back', data).subscribe((res: any) => {
      this.router.navigate(['/'])
    }, err => {
      console.log(err);
    })
  }

  get email() { return this.contactForm.get('email'); }
  get name() { return this.contactForm.get('name'); }
  get phone_number() { return this.contactForm.get('phone_number'); }
}
