<footer class="p-5">
  <div class="container">
    <div class="header py-4">
      REQUEST <br />CALL BACK
    </div>

    <div class="form">
      <form (ngSubmit)="contactUs(contactForm.value)" [formGroup]="contactForm">
        <div class="row">
          <div class="col-md-4">
            <input type="text" class="form-control" [ngClass]="{'error': name.invalid && (name.dirty || name.touched)}"
              formControlName="name" placeholder="Name">
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control"
              [ngClass]="{'error': email.invalid && (email.dirty || email.touched)}" formControlName="email"
              placeholder="Email">
          </div>
          <div class="col-md-4">
            <input type="text" class="form-control"
              [ngClass]="{'error': phone_number.invalid && (phone_number.dirty || phone_number.touched)}"
              formControlName="phone_number" placeholder="Phone">
          </div>
        </div>

        <button type="submit" class="submit-btn my-5" [disabled]="!contactForm.valid">
          <img src="assets/Images/icons/arrow.svg" width="100" alt="arrow" class="text-center">
        </button>
      </form>
    </div>

    <nav class="navbar navbar-expand-lg py-3">
      <div class="col-md-2">
        <a routerLink="/">
          <img src="assets/Images/textLogo.svg" alt="logo" />
        </a>
      </div>

      <div class="col-md-7">
        <ul class="navbar-nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link" routerLink="/about-us" style="cursor: pointer;">About us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/developments">Developments</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/contact-us">Contact us</a>
          </li>
        </ul>
      </div>

      <div class="col-md-3">
        <ul class="px-0 my-2 row align-items-md-center justify-content-end mx-0">
          <li class="nav-item col-3">
            <a href="https://web.facebook.com/VantageUrbanDevelopments" target="_blank" rel="noopener noreferrer">
              <i class="fa fa-facebook-square" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item col-3">
            <a href="https://www.instagram.com/vantagedevelopments/" target="_blank" rel="noopener noreferrer">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item col-3">
            <a href="https://www.linkedin.com/company/vantage-developments" target="_blank" rel="noopener noreferrer">
              <i class="fa fa-linkedin-square" aria-hidden="true"></i>
            </a>
          </li>

          <li class="nav-item col-3">
            <a href="https://www.youtube.com/channel/UCcioKKIO3UUhsqLHrcKLksA" target="_blank" rel="noopener noreferrer">
              <i class="fa fa-youtube" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div class="text-center">
      All rights reserved
    </div>
  </div>
</footer>