import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
  details:any = {}
  data:any = {}

  constructor(
    private _HomeService:HomeService,
    private _Activatedroute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('news').subscribe((res:any)=>{
      let id = this._Activatedroute.snapshot.paramMap.get("id");
      this.data = res
      this.details = res.news.find((item:any)=>item.id== id)
    },err=>{
      console.log(err);
    })
  }
}
