import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }
  get(param,q='') {
    return this.http.get(`${environment.API_URL}/${param}/${q}`);
  }

  set(param,values) {
    return this.http.post(`${environment.API_URL}/${param}`,values);
  }
}
