import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-make-your-choice',
  templateUrl: './make-your-choice.component.html',
  styleUrls: ['./make-your-choice.component.scss']
})
export class MakeYourChoiceComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
  }

  data: any = []
  constructor(
    private _homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this._homeService.get("units/analytics").subscribe(res => {
      this.data = res
    })
  }
}
