<div class="detail">

    <app-top-header title="{{data?.title}}"></app-top-header>
    <app-space></app-space>

    <section>
        <div class="content">
            <div class="title">{{details.title}}</div>
            <div class="desc">{{details.description}}</div>
        </div>
    </section>
</div>