<div class="make-choice">
  <div class="title">Make your choice</div>
  <div class="container">

    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let item of data">
        <div class="item" routerLink="/apartments" [queryParams]="{ type:item?.unit_type?.name}">
          <div class="number">{{item.counter}}</div>
          <div class="label">{{item.unit_type?.name}}</div>
        </div>
      </ng-template>
    </owl-carousel-o>

  </div>
</div>