import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  data:any = {}

  constructor(
    private _HomeService:HomeService
  ) {
  }
  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('about-us').subscribe(res=>{
      this.data = res
    },err=>{
      console.log(err);
    })
  }
}
