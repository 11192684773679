import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ApartmentsComponent } from './apartments/apartments.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { ConstructionProgressComponent } from './construction-progress/construction-progress.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DesignComponent } from './design/design.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HowToBuyComponent } from './how-to-buy/how-to-buy.component';
import { LeadersComponent } from './leaders/leaders.component';
import { LocationComponent } from './location/location.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { NewsComponent } from './news/news.component';
import { PhotoGalleryDetailComponent } from './photo-gallery-detail/photo-gallery-detail.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'construction', component: ConstructionProgressComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'location', component: LocationComponent },
  { path: 'developments', component: BenefitsComponent },
  { path: 'how-to-buy', component: HowToBuyComponent },
  { path: 'news', component: NewsComponent },
  { path: 'news/:id', component: NewsDetailsComponent },
  { path: 'image-detail/:id', component: PhotoGalleryDetailComponent },
  { path: 'leaders', component: LeadersComponent },
  { path: 'apartments', component: ApartmentsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'design', component: DesignComponent },
  { path: 'privacy', component: PrivacyComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
