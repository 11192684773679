import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-photo-gallery-detail',
  templateUrl: './photo-gallery-detail.component.html',
  styleUrls: ['./photo-gallery-detail.component.scss']
})
export class PhotoGalleryDetailComponent implements OnInit {
  details:any = {}

  constructor(
    private _HomeService:HomeService,
    private _Activatedroute:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('gallery').subscribe((res:any)=>{
      let id = this._Activatedroute.snapshot.paramMap.get("id");
      this.details = res.pictures.find((item:any)=>item.id== id)
    },err=>{
      console.log(err);
    })
  }

}
