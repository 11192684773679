<mat-drawer-container hasBackdrop="true">
  <mat-drawer #drawer mode="over" position="end">
    <div class="container side-fav p-5">
      <div class="icon-btn" style="color: black;" (click)="drawer.toggle()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
      <h3>FAVORITES</h3>
      <p>YOU CAN SAVE YOUR FAVORITE APARTMENTS HERE</p>
      <app-favorite-item></app-favorite-item>
      <div class="row">
        <div class="col-md-6">
          <p>SELECT ON THE LAYOUT</p>
        </div>
        <div class="col-md-6">
          <p>SEARCH BY FEATURES</p>
        </div>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer #about mode="over" position="start" class="about-us-nav">
    <div class="container about-side-fav p-5">
      <div class="row justify-content-between">
        <div class="icon-btn" (click)="about.toggle()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </div>
        <button
        class="btn btn-outline-success my-2 my-sm-0 mobile-view" (click)="linkTo(about,'/apartments')">
        SELECT AN APARTMENT
      </button>
      </div>
      <div class="top">
        <p pointer (click)="linkTo(about,'/about-us')">ABOUT US</p>
      </div>

      <div class="center">
        <p pointer (click)="linkTo(about,'/construction')">CONSTRUCTION PROGRESS</p>
        <p pointer (click)="linkTo(about,'/design')">DESIGN</p>
        <p pointer (click)="linkTo(about,'/gallery')">PHOTO GALLERY</p>
        <p pointer (click)="linkTo(about,'/location')">LOCATION</p>
        <p pointer (click)="linkTo(about,'/developments')">DEVELOPMENTS</p>
        <p pointer (click)="linkTo(about,'/how-to-buy')">HOW TO BUY</p>
      </div>

      <div class="bottom">
        <p pointer (click)="linkTo(about,'/leaders')">TEAM</p>
        <p pointer (click)="linkTo(about,'/news')">NEWS</p>
        <p pointer (click)="linkTo(about,'/contact-us')" class="mobile-view">CONTACT US</p>
        <p pointer (click)="linkTo(about,'/privacy')">PRIVACY POLICY</p>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <div class="container nav">
      <nav class="navbar navbar-expand-lg py-3">
        <div class="col-4 col-md-5">
          <ul class="navbar-nav">
            <li class="nav-item" (click)="about.toggle()">
              <mat-icon aria-hidden="false" aria-label="menu icon">menu</mat-icon>
              <a class="nav-link" pointer>Menu</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/contact-us">CONTACT US</a>
              <!-- <a class="nav-link" routerLink="/construction">CONSTRUCTION PROGRESS</a> -->
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" routerLink="/contact-us">CONTACT US</a>
            </li> -->
          </ul>
          <div class="menu-btn" (click)="about.toggle()">
            <div>Menu</div>
          </div>
          <div class="icon-btn" (click)="about.toggle()">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </div>
        </div>

        <div class="col-4 col-md-2">
          <a routerLink="/">
            <img src="assets/Images/brand_logo.png" width="100%" alt="" />
          </a>
        </div>

        <div class="col-4 col-md-5">
          <ul class="row align-items-center justify-content-end mx-0">
            <button
              class="btn btn-outline-success my-2 my-sm-0"
              [matMenuTriggerFor]="menu">
              SELECT AN APARTMENT
            </button>
            <!-- <div class="px-3">
              <img
                src="assets/Images/icons/heart.svg"
                width="23"
                pointer
                (click)="drawer.toggle()"
              />
            </div> -->
          </ul>
        </div>

        <mat-menu #menu="matMenu">
          <div class="row mx-0 menu" routerLink="/apartments">
            <div class="col-md-6 p-0">
              <img src="assets/Images/homePage1stImage.png" width="100%" />
            </div>
            <div class="col-md-6 row align-items-end">
              <p>SEARCH BY FEATURES</p>
            </div>
          </div>
        </mat-menu>
      </nav>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
