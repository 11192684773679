<div class="location">
  <app-top-header title="{{data?.name}}"></app-top-header>

  <section class="py-3">
    <img [src]="data?.cover" width="100%" alt="image" />

    <app-space></app-space>

    <div class="direction">
      <div class="row mx-0" style="min-height: 1300px">
        <div class="col-md-6">
          <div class="sticky">
            <p *ngIf="type=='foot'">On foot</p>
            <p *ngIf="type!='foot'">By car</p>

            <div class="man-walk-icon" (click)="setType('foot')" [ngClass]="{'active': type=='foot'}">
              <img src="assets/Images/icons/man.svg" />
            </div>
            <div class="car-icon" (click)="setType('car')" [ngClass]="{'active': type=='car'}">
              <img src="assets/Images/icons/car.svg" />
            </div>

            <mat-vertical-stepper [linear]="true" #stepper>
              <mat-step [stepControl]="" state="" *ngFor="let location of data?.locations">
                <ng-template matStepLabel *ngIf="type=='foot'">{{location.away_time_by_foot.slice(3,5)}} minutes</ng-template>
                <ng-template matStepLabel *ngIf="type!='foot'">{{location.away_time_by_car.slice(3,5)}} minutes</ng-template>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <div class="imga my-5" *ngFor="let location of data?.locations">
            <img [src]="location.image" width="100%" alt="image" />
            <div class="label">{{location.name}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="sec-div">
      <div class="container">
        <h1>
          <span>CENTURY CITY RESIDENCES</span> <br />
          HAS EVERYTHING TO LET YOU ENJOY YOURSELF AND THE COMPANY OF THOSE YOU
          LOVE
        </h1>
      </div>
    </div>

    <div class="third-div">
      <div class="container">
        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6 d-flex flex-column justify-content-center content">
            <h1>Century <sub>City</sub></h1>
            <p>
              MASTER-PLANNERS HAVE COME TOGETHER TO CREATE THE IDEAL USE OF
              GREEN VIEWS WITHIN THE ECO-CLUSTER CONCEPT WITHIN A 19-ACHER
              BOUTIQUE DEVELOPMENT, A HOME TO OVER 500 RESIDENTIAL UNITS.
              APPRECIATE A LIVING EXPERIENCE WITH AN EMPHASIS ON GREEN GARDEN
              AND PEDESTRIAN ZONES TO ALLOW RESIDENTS TO LEAD A TRANQUIL LIFE
              THAT IS RESTRICTED, RESERVED AND SAFE.
            </p>

            <div class="row">
              <div class="prev">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
              </div>
              <div class="next">
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-make-your-choice></app-make-your-choice>

  </section>
</div>
