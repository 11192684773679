<div class="design">
  <app-top-header title="DESIGN"></app-top-header>

  <div class="first-div">
    <img [src]="data?.cover" width="100%" alt="image" />
    <div class="container">
      <h1>{{data?.name}}</h1>
    </div>
  </div>

  <app-space></app-space>

  <div class="ceo container">
    <div class="row mx-0">
      <div class="col-md-4">
        <img [src]="data?.member?.picture" width="100%" />
      </div>
      <div class="col-md-6 d-flex flex-column justify-content-between">
        <img src="assets/Images/qoute.svg" width="40" />
        <p class="ml-5">{{data?.quote}}</p>
        <p>{{data?.member?.small_description}}</p>
      </div>
    </div>
  </div>

  <app-space></app-space>

  <div class="container">
    <div [style.height]="(pageHeight * 130) / 100 + 'px'" class="secondSlider">
      <div [style.height]="400 + 'px'" class="text">
        <h1 [style.font-size]="pageWidth / 24 + 'px'">
          <span>CENTURY CITY RESIDENCES<br /></span> HAS EVERYTHING TO LET YOU
          ENJOY YOURSELF AND THE COMPANY OF THOSE YOU LOVE
        </h1>
      </div>
      <div class="row mx-0" *ngFor="let item of data?.design_quotes" [style.height]="(pageHeight * 90) / 100 + 'px'">
        <div class="col-md-5">
          <h6 class="ml-4">
            <img
              src="assets/Images/qoute.svg"
              width="20"
              class="mb-3"
              style="width: 40px !important; position: relative; display: block"
            />{{item?.quote}}</h6>
        </div>
        <div class="col-md-7 p-0 m-0">
          <img [src]="item?.picture" />
        </div>
      </div>
    </div>
  </div>

  <app-space></app-space>

  <section class="gal py-5">
    <h2 class="my-0 title">THE TERRITORY</h2>
    <owl-carousel-o [options]="customOpt">
      <ng-template
        carouselSlide
        *ngFor="let item of territory_pictures"
      >
        <div
          class="item"
          routerLink="/image-detail"
          [style.background-image]="'url('+item+')'"
        ></div>
      </ng-template>
    </owl-carousel-o>
  </section>

  <app-space></app-space>

  <section class="text py-5">
    <div class="row justify-content-center mx-0">
      <div class="col-md-5">
        <p>{{data?.territory_description}}</p>
        <!-- <h4 class="m-0">INFRASTRUCTURE</h4> -->
      </div>
    </div>
  </section>

  <app-space></app-space>

  <section class="gallery py-5">
    <div class="py-5">
      <h2 class="my-0 title container">LOBBY</h2>
      <owl-carousel-o [options]="customOptions">
        <ng-template
          carouselSlide
          *ngFor="let item of lobby_pictures"
        >
          <div
            class="item"
            [style.background-image]="'url('+item+')'"
          ></div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </section>

  <div style="background-color: #f8f3ef; height: 200px"></div>

  <section class="img">
    <h2 class="my-0 title text-center">LUSH GREEN HOMES</h2>
    <img src="{{data?.luxurious_gallery_main_picture}}" width="100%" />
  </section>

  <app-space></app-space>

  <section class="texting py-5">
    <div class="row mx-0 justify-content-around">
      <div class="col-md-3">
        <p>{{data?.luxurious_description}}</p>
      </div>
      <div class="col-md-4">
        <h1>
          <span>CENTURY CITY RESIDENCES <br /></span> HAS EVERYTHING TO LET YOU
          ENJOY YOURSELF AND THE COMPANY OF THOSE YOU LOVE
        </h1>
      </div>
    </div>
  </section>

  <app-space></app-space>

  <app-make-your-choice></app-make-your-choice>
</div>
