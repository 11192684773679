import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-apartment-details',
  templateUrl: './apartment-details.component.html',
  styleUrls: ['./apartment-details.component.scss']
})
export class ApartmentDetailsComponent implements OnInit {
  emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  requestForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private _HomeService: HomeService,
    public dialogRef: MatDialogRef<ApartmentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.requestForm = this.formBuilder.group({
      email: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      unit:new FormControl(this.data?.id)
    })
   }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  requestCall(values) {
    this._HomeService.set('units/call-request', values).subscribe((res: any) => {
    this.dialogRef.close();
    }, err => console.log(err))
  }

  get email() { return this.requestForm.get('email'); }
  get name() { return this.requestForm.get('name'); }
  get phone() { return this.requestForm.get('phone'); }
}
