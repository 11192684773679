import {
  Directive,
  ElementRef,
} from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
@Directive({
  selector: '[appAppHorizontalScroll]',
})
export class AppHorizontalScrollDirective {
  constructor(private el: ElementRef) { }

  ngAfterViewInit(): void {
    gsap.registerPlugin(ScrollTrigger);
    this.projectsSectionAnimation()
  }

  projectsSectionAnimation() {
    gsap.to(this.el.nativeElement, {
      yPercent: -80,
      ease: "none",
      scrollTrigger: {
        trigger: this.el.nativeElement,
        scrub: 1,
        start: "top center-=35%",
      },
    });
  }
}
