<div class="about-us">
    <app-top-header title="ABOUT US"></app-top-header>

    <section class="py-3">
        <header>
            <img [src]="data?.image" width="100%" alt="image" />
            <p>{{data?.description}}</p>
        </header>

        <div id="vision">
            <div class="row mx-0">
                <div class="col-md-7 px-0">
                    <img [src]="data?.vision_image" width="100%" alt="image" />
                </div>
                <div class="col-md-5">
                    <h1>Our<br /> Vision</h1>
                    <p>{{data?.vision_description}}</p>
                </div>
            </div>
        </div>

        <div id="mission">
            <div class="row mx-0">
                <div class="col-md-5">
                    <h1>Our<br /> Mission</h1>
                    <p>{{data?.mission_description}}</p>
                </div>
                <div class="col-md-7 px-0">
                    <img [src]="data?.mission_image" width="100%" alt="image" />
                </div>
            </div>
        </div>

        <app-make-your-choice></app-make-your-choice>

    </section>
</div>