import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home/home.service';
import { Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ApartmentDetailsComponent } from '../dialogs/apartment-details/apartment-details.component';

@Component({
  selector: 'app-apartments',
  templateUrl: './apartments.component.html',
  styleUrls: ['./apartments.component.scss']
})
export class ApartmentsComponent implements OnInit {
  data: any = []
  types: any = []

  selectedType: any = ''
  selectedRoom: any = ''
  sort: any = ''


  price_gte: number = 0;
  price_lte: number = 3000000;

  square_area_gte: number = 0;
  square_area_lte: number = 3000;

  optionsPrice: Options = {
    floor: 0,
    ceil: 10000000
  };
  optionsArea: Options = {
    floor: 0,
    ceil: 3000
  };
  constructor(
    private _HomeService: HomeService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getData();
    this.getTypes()
  }

  getTypes() {
    this._HomeService.get(`units/types`).subscribe(res => {
      this.types = res;
      this.activatedRoute.queryParams.subscribe((params) => {
        const type = params["type"];
        let filteredType = this.types.find(item=>item.name.includes(type))
        if(filteredType){
          this.selectedType = filteredType.id
          this.filterDataByType(filteredType.id)
        }
      });
    }, err => {
      console.log(err);
    })
  }

  getData(unit_type = this.selectedType, rooms = this.selectedRoom) {
    this._HomeService.get('units', `?unit_type=${unit_type}&rooms=${rooms}&price__gte=${this.price_gte}&price__lte=${this.price_lte}&square_area__gte=${this.square_area_gte}&square_area__lte=${this.square_area_lte}`).subscribe(res => {
      this.data = res
    }, err => {
      console.log(err);
    })
  }

  sortData(e) {
    this._HomeService.get('units', `?unit_type=${this.selectedType}&ordering=${e.value}`).subscribe(res => {
      this.data = res
    }, err => {
      console.log(err);
    })
  }

  filterDataByType(e= this.selectedType){
      let selected_type = this.types.find(item => item.id == e)
    
      this.optionsPrice = {
        floor: selected_type?.min_budget ?? 0,
        ceil: selected_type?.max_budget ?? 10000000
      }
      this.optionsArea = {
        floor: selected_type?.min_area ?? 0,
        ceil: selected_type?.max_area ?? 3000
      }
  
      this._HomeService.get('units', `?unit_type=${e}&rooms=${this.selectedRoom}&price__gte=${this.price_gte}&price__lte=${this.price_lte}&square_area__gte=${this.square_area_gte}&square_area__lte=${this.square_area_lte}`).subscribe(res => {
        this.data = res
      }, err => {
        console.log(err);
      })
    
  }

  filterDataByPrice(e) {
    this._HomeService.get('units', `?unit_type=${this.selectedType}&rooms=${this.selectedRoom}&price__gte=${e.value}&price__lte=${e.highValue}&square_area__gte=${this.square_area_gte}&square_area__lte=${this.square_area_lte}`).subscribe(res => {
      this.data = res
    }, err => {
      console.log(err);
    })
  }

  filterDataByArea(e) {
    this._HomeService.get('units', `?unit_type=${this.selectedType}&rooms=${this.selectedRoom}&price__gte=${this.price_gte}&price__lte=${this.price_lte}&square_area__gte=${e.value}&square_area__lte=${e.highValue}`).subscribe(res => {
      this.data = res
    }, err => {
      console.log(err);
    })
  }

  reset() {
    this.selectedType = ''
    this.selectedRoom = ''
    this.sort = ''
    this.getData()
  }

  openDialog(obj:any): void {
    const dialogRef = this.dialog.open(ApartmentDetailsComponent, {
      width: '75%',
      height:"90%",
      maxWidth:"1320px",
      data: obj,
    });

    dialogRef.afterClosed();
  }
}
