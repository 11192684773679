import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  data:any = {}

  type:any = 'foot'

  constructor(
    private _HomeService:HomeService
  ) {
  }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('location-headline').subscribe(res=>{
      this.data = res
    },err=>{
      console.log(err);
    })
  }

  setType(type){
    this.type = type
  }

}
