import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent implements OnInit {
  data:any = {}

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav:false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
  }
  constructor(
    private _HomeService:HomeService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('development').subscribe(res=>{
      this.data = res
    },err=>{
      console.log(err);
    })
  }

}
