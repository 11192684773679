<div class="construction">
    <div class="header">
        <h1>PROGRESS</h1>
    </div>
    <!-- <img src="assets/Images/construction.png" width="100%" /> -->
    <div class="row mx-0 py-5 justify-content-center " *ngFor="let year of data;let i = index"
        [ngClass]="{'flex-row-reverse': i%2!=0}">
        <div class="col-md-4">
            <img [src]="year.picture" class="w-100" />
        </div>
        <div class="col-md-6 year" [ngClass]="{'text-right': i%2!=0}">
            {{year.year}}
        </div>
    </div>
</div>