<section
  class="top-header section--top ui-dark-background page-top--floating-title">
  <div class="container page-top">
    <div class="page-top__spacer"></div>

    <div class="ui-dark">
      <div class="breadcrumbs">
        <div class="row row--middle-xs mx-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">{{brad}}</li>
              <li class="breadcrumb-item" aria-current="page"></li>
            </ol>
          </nav>
        </div>
      </div>

      <h2 class="my-0 title">{{title}}</h2>
    </div>
  </div>
</section>
