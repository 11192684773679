<div class="row">
    <div class="col-md-7">
        <img [src]="data.sketch" width="80%" height="450" alt="{{data.name}}" />
    </div>
    <div class="col-md-4 d-flex flex-column justify-content-between">
        <div>
            <p>{{data.square_area}} M2</p>
            <p>{{data.rooms}} ROOMS</p>
            <p class="location">HOUSING {{data.housing}}</p>
            <p class="location">FLOOR {{data.floor}}</p>
            <p class="mr-1 price">Price : {{data.price}} EGP</p>
        </div>

        <div class="mt-5">
            <h2>Request a call</h2>
            <form (ngSubmit)="requestCall(requestForm.value)" [formGroup]="requestForm">
                <div class="my-2">
                    <input type="text" class="form-control"
                        [ngClass]="{'error': name.invalid && (name.dirty || name.touched)}" formControlName="name"
                        placeholder="Name">
                </div>
                <div class="my-2">
                    <input type="text" class="form-control"
                        [ngClass]="{'error': email.invalid && (email.dirty || email.touched)}" formControlName="email"
                        placeholder="Email">
                </div>
                <div class="my-2">
                    <input type="text" class="form-control"
                        [ngClass]="{'error': phone.invalid && (phone.dirty || phone.touched)}"
                        formControlName="phone" placeholder="Phone">
                </div>

                <button type="submit" class="submit-btn my-5" [disabled]="!requestForm.valid">
                    <img src="assets/Images/icons/arrow.svg" width="100" alt="arrow" class="text-center">
                </button>
            </form>
        </div>
    </div>
</div>