import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-construction-progress',
  templateUrl: './construction-progress.component.html',
  styleUrls: ['./construction-progress.component.scss']
})
export class ConstructionProgressComponent implements OnInit {
  data:any = []
  constructor(
    private _HomeService:HomeService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('years').subscribe(res=>{
      this.data = res
    },err=>{
      console.log(err);
    })
  }

}
