import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home/home.service';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-how-to-buy',
  templateUrl: './how-to-buy.component.html',
  styleUrls: ['./how-to-buy.component.scss']
})
export class HowToBuyComponent implements OnInit {
  type: any = 'cash'
  data: any = {}

  optionsUnit: Options = {
    floor: 800000,
    ceil: 2000000,
    step: 200000,
    combineLabels: (max, min): string => {
      return ''
    }
  };

  unit_gte: number = 800000;
  unit_lte: number = 1000000;

  yearsUnit: Options = {
    floor: 1,
    ceil: 10,
    step: 1,
    combineLabels: (max, min): string => {
      return ''
    }
  };

  year_gte: number = 1;
  year_lte: number = 3;

  downUnit: Options = {
    floor: 100000,
    ceil: 2000000,
    step: 200000,
    combineLabels: (max, min): string => {
      return ''
    }
  };

  down_gte: number = 100000;
  down_lte: number = 300000;

  min_monthly: number = 0
  max_monthly: number = 0
  constructor(
    private _HomeService: HomeService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  setType(type) {
    this.type = type
  }

  getData() {
    this._HomeService.get('contact_us').subscribe(res => {
      this.data = res[0]
    }, err => {
      console.log(err);
    })
  }

  downChange(e) {
    if (e.pointerType == 1) {
      if (e.highValue < 200000) {
        this.down_gte = 200000
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      } else {
        this.down_gte = this.down_lte - 200000
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      }
    } else {
      if (e.value > 1800000) {
        this.down_lte = 1800000;
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      } else {
        this.down_lte = this.down_gte + 200000
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      }
    }
  }

  unitsChange(e) {
    if (e.pointerType == 1) {
      if (e.highValue < 1000000) {
        this.unit_gte = 1000000
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      } else {
        this.unit_gte = this.unit_lte - 200000
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      }
    } else {
      if (e.value > 1800000) {
        this.unit_lte = 1800000;
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      } else {
        this.unit_lte = this.unit_gte + 200000
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      }
    }
  }

  yearsChange(e) {
    if (e.pointerType == 1) {
      if (e.highValue < 3) {
        this.year_lte = 3;
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      } else {
        this.year_gte = this.year_lte - 2
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte)
      }
    } else {
      if (e.value > 8) {
        this.year_gte = 8;
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      } else {
        this.year_lte = this.year_gte + 2
        this.CalculateMonthly(this.unit_gte, this.unit_lte, this.year_gte, this.year_lte,this.down_gte,this.down_lte)
      }
    }
  }

  CalculateMonthly(unit_min = this.unit_gte, unit_max = this.unit_lte, year_min = this.year_gte, year_max = this.year_lte,down_min=this.down_gte,down_max=this.down_lte) {
    this.min_monthly = Math.floor(((.05 * year_min * (unit_min-down_min)) + (unit_min-down_min)) / 12 * year_min);
    this.max_monthly = Math.floor(((.05 * year_max * (unit_max-down_max)) + (unit_max-down_max)) / 12 * year_max);
  }
}
