import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent implements OnInit {
  data:any = {}

  lobby_pictures:any = []
  territory_pictures:any = []

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav:false,
    center:true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
  }
  customOpt: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav:false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
  }
  pageHeight;
  pageWidth;
  constructor(
    private _HomeService:HomeService
  ) {
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('design').subscribe((res:any)=>{
      this.data = res;
      let lobby_pictures = [res.loppy_gallery_right_picture,res.loppy_gallery_middle_picture,res.loppy_gallery_left_picture]
      let territory_pictures = [res.territory_gallery_right_picture,res.territory_gallery_middle_picture,res.territory_gallery_left_picture]
      
      this.lobby_pictures = lobby_pictures
      this.territory_pictures = territory_pictures
    },err=>{
      console.log(err);
    })
  }
}
