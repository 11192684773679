import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-leaders',
  templateUrl: './leaders.component.html',
  styleUrls: ['./leaders.component.scss']
})
export class LeadersComponent implements OnInit {
  data:any = [];

  constructor(
    private _HomeService:HomeService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('teams-info').subscribe((res:any)=>{
      this.data = res;
    },err=>{
      console.log(err);
    })
  }
}
