<div class="gallery">
  <app-top-header title="{{data?.name}}"></app-top-header>

  <owl-carousel-o [options]="customOptions">
    <ng-template carouselSlide *ngFor="let item of data?.pictures">
      <div class="item" routerLink="/image-detail/{{item.id}}" [style.background-image]="'url('+item.picture+')'">
        <div class="title">{{item.name}}</div>
      </div>
    </ng-template>
  </owl-carousel-o>

</div>