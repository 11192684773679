<div id="privacy">
  <app-top-header title="PRIVACY POLICY"></app-top-header>
  <app-space></app-space>

  <div class="container">
    <p>
      The purpose of this privacy policy is to disclose to you what information we may collect, how we may collect it,
      with whom we may share it, and certain other matters related to such information, including the choices you have
      regarding our collection of information and our use and disclosure to other parties of information we may have
      collected from you.
<br/>
<br/>
      Vantage Developments is committed to protecting your privacy. We use the information we collect about you to
      facilitate commercial transactions and to provide you with a more personalized experience. We have created this
      privacy policy statement in order to demonstrate our firm commitment to protecting the privacy of all of our
      customers and visitors. The following Privacy Statement discloses the information-gathering and dissemination
      practices and policies of Vantage Developments.
<br/>
<br/>
      Except as otherwise discussed in this Privacy Policy, this document only addresses the use and disclosure of
      information we collect from you online. For some services, we either collect and transfer personal information to
      a service provider you have selected, or you may visit other merchants through links on our site who may collect
      and use your personal information. We encourage you to ask questions and to be very cautious before you disclose
      your personal information to others.
      Please read the following to learn more about our data collection policies and practices.
<br/>
<br/>
      Types of information that may be collected:
<br/>
<br/>
      The personally identifiable information we collect is used to facilitate delivery of products and/or services to
      our customers. In the process of providing these products and/or services, we require our customers to provide
      certain personally identifiable information.
      Specific details may include, but is not limited to the following and may be subject to change:
<br/>
<br/>
      In the case of contests, promotions, and/or newsletter registrations: We require all users and/or customers
      wishing to participate in any of our contests, drawings, sweepstakes, promotions, or newsletters to complete a
      Participant Registration Form. During this registration process we generate and collect certain personally
      identifiable information.
<br/>
<br/>
      The personally identifiable information that may be collected from our customers in completion of a registration
      form: Customer's first name, last name, zip code, phone number, age and email address. Winners of contests,
      drawings, sweepstakes, and/or promotions will be required to provide additional personally identifiable
      information such as a shipping address and telephone number.
      If a customer sends us personal correspondence, such as emails or letters, we may collect such information into a
      file specific to you.
      How is information collected?
<br/>
<br/>
      We may collect information from you in several different areas on the Site, including but not limited to those
      described below:
<br/>
<br/>
      Registration You may be asked first to complete a registration form and provide personally identifiable
      information; you may also be asked to choose a user ID and password in order to take advantage of certain features
      the Site may choose to offer, including, but not limited to, Newsletters, Contests, Forums, Fantasy or other
      subscription based services.
<br/>
<br/>
      Newsletters and Site Emails We may offer free newsletters to share information with you about the Site, about us
      and our affiliates and subsidiaries, or about our business partners and advertisers and sponsors. You may receive
      a newsletter either by registering for a particular newsletter or in conjunction with a special feature or
      membership for which you have registered on the Site. You may cancel your free email subscription by following the
      instructions provided on the Site or, in some instances, in the designated area of certain newsletters or emails.
<br/>
<br/>
      Contests or Sweepstakes We may operate contests and sweepstakes through the Site, which may require registration.
      Your personally identifiable information may be used to contact you for winner notification, prize delivery
      confirmation or other related purposes. We may ask your permission to allow us to post publicly some of your
      information on the Site, such as on a winner's page, in the event you win a contest or sweepstakes. Except as
      required by applicable law, we will not post your information without your permission. In some instances, a third
      party advertiser or sponsor may operate or host a contest or sweepstakes on the Site, and collect your
      information. In those cases, where we are not the operator or host of the contest or sweepstakes, we have no
      control over the information collected and accordingly urge you to review the advertiser's or sponsor's privacy
      policy applicable to the contest or sweepstakes before participating.
<br/>
<br/>
      Surveys or Voting From time-to-time we may request personally identifiable information from you via surveys or
      voting polls. Participation is voluntary and you will have the opportunity to decide whether or not to disclose
      information. At times, you may have to register to vote or to take part in a survey.
<br/>
<br/>
      Cookies As with many Websites, the Site uses standard technology called "cookies," which are small data files that
      are transferred to your computer when you allow your browser to accept cookies. Cookies automatically identify
      your Web browser to the Site whenever you visit the Site, and make using the Site easier for you by saving your
      passwords, purchases, and preferences. By tracking how and when you use the Site, cookies help us determine which
      areas are popular and which are not. Many improvements and updates to the Site are based on data obtained from
      cookies. Accepting cookies allows you, among other things, to personalize your experience on the Site. Cookies may
      also allow the Site to present to you advertising which may be of interest to you. If you do not want information
      collected through the use of cookies, you generally can opt out of providing this information by turning the
      cookies off in your browser; however, some areas of the Site may not provide you with a personalized experience if
      you have disabled the use of cookies.
      IP Addresses and Click-stream Data We may collect IP addresses and/or click-stream data for purposes of system
      administration and to report aggregate information to our advertisers and sponsors. An IP address is a number that
      is automatically assigned to your computer whenever you access the Internet. Our computers identify your computer
      by its IP address. When you request Web pages from the Site, our computers log your IP address.
<br/>
<br/>
      Click-stream data is information collected by our computers when you request Web pages from the Site. Click-stream
      data may include such information as the page served, the time, the source of the request, the type of browser
      making the request, the preceding page view and other such non-personal information. When analyzed, this data
      helps us analyze how visitors arrive at the Site, what type of content is most popular, what type of visitors in
      the aggregate are interested in particular kinds of content and advertising, and the like.
<br/>
<br/>
      Transaction Information If you order any information from our Site, buy any of our products or subscribe for any
      of the services offered on our Site, we will ask you to complete an order form that will request certain
      information from you, including financial information such as a credit card number and expiration date and general
      contact, billing and shipping information. We will use this information to complete the transaction that you have
      requested, i.e., to deliver the information, products or other services that you order, and to invoice you. Such
      information is collected and stored through encrypted means for your protection.
<br/>
<br/>
      How we use the information we collect?
      We internally use personally identifiable information about our customers to improve our marketing and promotional
      efforts, to statistically analyze site usage, to improve our content and product offerings and to customize our
      site's content and layout. We believe these uses allow us to improve our site and provide better service to our
      customers.
<br/>
<br/>
      We may occasionally use a customer's email address and, in rare situations mailing address or phone number, to
      contact a customer regarding administrative matters or notices.
<br/>
<br/>
      We may use information in the file we maintain about a customer to resolve disputes and troubleshoot problems.
      With whom the information may be shared
      Information we collect is only accessed internally by our company staff and is never shared with third parties.
<br/>
<br/>
      What security precautions are in place to protect against the loss, misuse, or alteration of your information?
      We use industry standard efforts to safeguard the confidentiality of your personally identifiable information.
      However, please keep in mind that "perfect security" does not exist on the Internet and/or the World Wide Web.
      Correcting and updating personal information.
      If your personally identifiable information changes (such as your zip code), or if you no longer desire to access
      our Site, we will endeavor to provide a way to correct, update or remove the personal data you have provided to
      us. This can be done by making changes to your profile, by using an option out message in an email, or by emailing
      us at: info@Vantage-Developments.com
    </p>
  </div>

  <app-make-your-choice></app-make-your-choice>
</div>