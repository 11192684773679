<div id="home">
  <!-- Part ONE -->
  <div [style.height]="(pageHeight * 80) / 100 + 'px'" class="partOne"
    [style.backgroundImage]="'url('+'assets/Images/homePage1stImage.png'+')'">
    <!-- <img src="assets/Images/homePage1stImage.png" /> -->
    <h1 class="powered" appAppHorizontalScroll>Powered</h1>
    <h1 class="by" appAppHorizontalScroll>By</h1>
    <h2 class="innovation" appAppHorizontalScroll>Innovation</h2>
  </div>
  <!-- Part TWO -->
  <div [style.height]="(pageHeight * 120) / 100 + 'px'" class="partTwo">
    <img src="assets/Images/homePage2ndImage.png" />
    <img class="circle" src="assets/Images/circleArrowDown.png" />
    <div class="text" [style.width]="pageWidth / 5.1 + 'px'" appAppHorizontalScroll>
      <h1 [style.font-size]="pageWidth / 68.57 + 'px'">{{data?.upper_gallery_title}}</h1>
      <h2 [style.font-size]="pageWidth / 115 + 'px'">{{data?.upper_gallery_description}}</h2>
    </div>
    <div class="gates py-5">
      <img src="{{data?.upper_gallery_left_picture}}" />
      <img src="{{data?.upper_gallery_middle_picture}}" class="bigger" />
      <img src="{{data?.upper_gallery_right_picture}}" />
      <h1 [style.font-size]="pageWidth / 5.13 + 'px'" appAppHorizontalScroll>Elegant design</h1>
      <img src="assets/Images/plusCircle.png" class="plus" />
    </div>
  </div>
  <!-- Part Three -->
  <div [style.height]="(pageHeight * 100) / 100 + 'px'" class="mapPart m-0 pt-3">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mb-3">
          <img src="assets/Images/homePageThirdDivMap.png" class="map" />
        </div>
        <div class="col-md-4" appAppHorizontalScroll>
          <div class="text">
            <h1>YOUR</h1>
            <h1>HOME.</h1>
            <h1>YOUR</h1>
            <h2>HEAVEN</h2>
          </div>
          <h3>Master Plan</h3>
        </div>
      </div>
    </div>
  </div>

  <!-- Part Four -->
  <div [style.height]="(pageHeight * 70) / 100 + 'px'" class="leafs">
    <img src="assets/Images/homePageDivFour.png" />
    <div class="text py-5" appAppHorizontalScroll>
      <h1><span>LIFE</span></h1>
      <h1>AMONG PARKS AND</h1>
      <h1>ICONIC <span>CAIRO</span></h1>
      <h1><span>SIGHTS</span></h1>
    </div>
    <h2 [style.font-size]="pageWidth / 9.6 + 'px'" appAppHorizontalScroll>ICONIC</h2>
    <h3 [style.font-size]="pageWidth / 7 + 'px'" appAppHorizontalScroll>Lights</h3>
  </div>

  <!-- Part Five -->
  <div [style.height]="(pageHeight * 80) / 100 + 'px'" class="firstSlider">
    <img [src]="data?.iconic_gallery_main_picture" />
    <div class="text">
      <h1 [style.font-size]="pageWidth / 12.5 + 'px'" appAppHorizontalScroll>{{data?.iconic_gallery_first_title}}</h1>
      <h2 [style.font-size]="pageWidth / 12.5 + 'px'" appAppHorizontalScroll>{{data?.iconic_gallery_second_title}}</h2>
      <h3 [style.font-size]="pageWidth / 80 + 'px'" appAppHorizontalScroll>{{data?.iconic_gallery_description}}</h3>
    </div>
  </div>

  <!-- Part Five -->
  <!-- <div [style.height]="(pageHeight * 80) / 100 + 'px'" class="firstSlider">
    <div class="row">
      <div class="col-md-6">
        <img src="assets/Images/homePageDivFive.png" />
      </div>
      <div class="col-md-6">
        <div class="text">
          <h1 [style.font-size]="pageWidth / 12.5 + 'px'">CENTURY CITY</h1>
          <h2 [style.font-size]="pageWidth / 12.5 + 'px'">PARK</h2>
          <h3 [style.font-size]="pageWidth / 80 + 'px'">
            THE ONLY COMPOUND IN NEW CAIRO THAT HAS 75% OF GREENERY VERSUS ONLY 25% OF
            BUILDING ON LAND.
          </h3>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Part SIX -->
  <div [style.height]="(pageHeight * 130) / 100 + 'px'" class="secondSlider">
    <div [style.height]="(pageHeight * 40) / 100 + 'px'" class="text">
      <h1 [style.font-size]="pageWidth / 24 + 'px'" appAppHorizontalScroll>
        <span>CENTURY CITY RESIDENCES</span> HAS EVERYTHING TO LET YOU ENJOY
        YOURSELF AND THE COMPANY OF THOSE YOU LOVE
      </h1>
    </div>
    <owl-carousel-o [options]="customOptions" #owlCard>
      <ng-template carouselSlide *ngFor="let item of data?.slider_pictures">
        <div class="row mx-0" [style.height]="(pageHeight * 90) / 100 + 'px'">
          <div class="col-md-6 p-0 m-0">
            <img [src]="item.picture" />
          </div>
          <div class="col-md-5">
            <h5 [style.font-size]="pageWidth / 8.57 + 'px'" class="ml-5">{{item.name}}</h5>
            <h6 [style.font-size]="pageWidth / 110 + 'px'" class="ml-4">{{item.description}}</h6>
            <div class="arrows">
              <img src="assets/Images/circleArrowLeft.png" class="mr-1 ml-4" (click)="owlCard.prev()" />
              <img src="assets/Images/circleArrowRight.png" class="ml-1" (click)="owlCard.next()" />
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
    <!-- <div class="row mx-0" [style.height]="(pageHeight * 90) / 100 + 'px'">
      <div class="col-md-7 p-0 m-0">
        <img src="assets/Images/homePageDivSix.png" />
      </div>
      <div class="col-md-5">
        <h5 [style.font-size]="pageWidth / 8.57 + 'px'" class="ml-5">
          Century City
        </h5>
        <h6 [style.font-size]="pageWidth / 110 + 'px'" class="ml-4">
          MASTER-PLANNERS HAVE COME TOGETHER TO CREATE THE IDEAL USE OF GREEN
          VIEWS WITHIN THE ECO-CLUSTER CONCEPT WITHIN A 19-ACHER BOUTIQUE
          DEVELOPMENT, A HOME TO OVER 500 RESIDENTIAL UNITS. APPRECIATE A LIVING
          EXPERIENCE WITH AN EMPHASIS ON GREEN GARDEN AND PEDESTRIAN ZONES TO
          ALLOW RESIDENTS TO LEAD A TRANQUIL LIFE THAT IS RESTRICTED, RESERVED AND
          SAFE.
        </h6>
        <div class="arrows">
          <img src="assets/Images/circleArrowLeft.png" class="mr-1 ml-4" />
          <img src="assets/Images/circleArrowRight.png" class="ml-1" />
        </div>
      </div>
    </div> -->
  </div>
  <!-- Part Seven -->

  <div [style.height]="(pageHeight * 100) / 100 + 'px'" class="partTwo partSeven">
    <div class="gates">
      <img src="assets/Images/homePageSecondDivOne.png" />
      <img src="assets/Images/homePageSecondDivTwo.png" class="bigger" />
      <img src="assets/Images/homePageSecondDivThree.png" />
      <h1 [style.font-size]="pageWidth / 7 + 'px'" appAppHorizontalScroll>Design options</h1>
      <h3 [style.font-size]="pageWidth / 9.6 + 'px'" appAppHorizontalScroll>INTERIOR</h3>
      <img src="assets/Images/plusCircle.png" class="plus" />
    </div>
  </div>

  <!-- Part Eight -->
  <div [style.height]="(pageHeight * 110) / 100 + 'px'" class="partEight">
    <div class="ceoPhoto">
      <div class="text">
        <h1 [style.font-size]="pageWidth / 24 + 'px'" appAppHorizontalScroll>INTERVIEW</h1>
        <h2 [style.font-size]="pageWidth / 24 + 'px'" appAppHorizontalScroll>WITH THE COMPANY CEO</h2>
      </div>
      <div *ngIf="!isVideoShowen">
        <img src="assets/Images/CEO.png" appAppHorizontalScroll />
      </div>
      <div *ngIf="isVideoShowen">
        <iframe width="560" height="415" [src]="videoLink" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
      <img src="assets/Images/playCircle.png" class="play" (click)="toggleVideoShowen()" />
    </div>
  </div>

  <!-- Part Nine  -->
  <div class="partNin py-5" *ngIf="data?.facility_pictures?.length != 0">
    <div class="container">
      <div class="row">
        <div class="col-md-4" appAppHorizontalScroll>
          <h1>FACILITIES</h1>
          <img src="assets/Images/circleArrowLeft.png" (click)="faOwlCar.prev()" class="leftArrow" />
          <img src="assets/Images/circleArrowRight.png" (click)="faOwlCar.next()" class="rightArrow" />
        </div>
        <div class="col-md-8" appAppHorizontalScroll>
          <owl-carousel-o [options]="faCustomOptions" #faOwlCar>
            <ng-template carouselSlide *ngFor="let facility_data of data?.facility_pictures">
              <!-- <div class="col-md-4"> -->
              <app-facility-card [item]="facility_data"></app-facility-card>
              <!-- </div> -->
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>

  <!-- Part Ten  -->
  <app-make-your-choice></app-make-your-choice>
</div>