<ng-container *ngIf="!detail">
  <div class="favorite" (click)="showDetails()">
    <div class="rate py-2">4.6</div>
    <div class="row">
      <div class="col-3">
        <img src="assets/Images/apart.svg" width="100%" />
      </div>
      <div class="col-5">
        <p>100 M2</p>
        <p>2 ROOMS</p>
        <p class="location">HOUSING 4</p>
        <p class="location">FLOOR 2</p>
      </div>
      <div class="col-4">
        <div class="row">
          <p class="mr-1">1,400,000 EGP</p>
          <img src="assets/Images/icons/heart.svg" width="25" />
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="detail">
  <div class="detail">
    <div class="row">
      <div class="col-md-6">
        <div class="title">
          APARTMENT NUMBER
        </div>
        <div class="rate py-2">4.6</div>
      </div>
    </div>

    <div class="row">
      <div class="col-6 d-flex flex-column justify-content-between">
        <div>
          <p>100 M2</p>
          <p>2 ROOMS</p>
        </div>
        <div>
          <p class="location">HOUSING 4</p>
          <p class="location">FLOOR 2</p>
        </div>
      </div>
      <div class="col-6">
        <img src="assets/Images/apart.svg" width="100%" />
      </div>
    </div>

    <div class="row py-3">
      <div class="col-md-6">
        <div class="price">
          1,400,000 EGP
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="submit my-3">
          MAKE A RESERVATION
        </div>
      </div>
    </div>

    <div class="gallery py-5">
      <div class="header">
        <h1>INTERIOR</h1>
      </div>
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9]">
          <div
            class="item"
            [style.background-image]="'url(assets/Images/gallery.png)'">
            <div class="title">FACILITIES</div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</ng-container>
