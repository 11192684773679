<div class="leaders">
  <app-top-header title="{{data?.title}}"></app-top-header>

  <div style="height: 200px" class="container">
    <p>{{data?.description}}</p>
</div>

  <div class="container">
    <div class="row">
      <div class="col-md-4 mb-4" *ngFor="let item of data?.members">
        <img [src]="item.picture" width="100%" height="400" style="object-fit: cover;" />
        <h5>{{item.name}}</h5>
        <h6>{{item.position}}</h6>
      </div>
    </div>
  </div>

  <app-space></app-space>

  <app-make-your-choice></app-make-your-choice>
</div>
