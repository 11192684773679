import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatStepperModule } from '@angular/material/stepper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConstructionProgressComponent } from './construction-progress/construction-progress.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LocationComponent } from './location/location.component';

import { TopHeaderComponent } from './components/top-header/top-header.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { HowToBuyComponent } from './how-to-buy/how-to-buy.component';

import { MatSliderModule } from '@angular/material/slider';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';
import { FavoriteItemComponent } from './components/favorite-item/favorite-item.component';
import { MakeYourChoiceComponent } from './components/make-your-choice/make-your-choice.component';
import { NewsComponent } from './news/news.component';
import { NewItemComponent } from './components/new-item/new-item.component';
import { PhotoGalleryDetailComponent } from './photo-gallery-detail/photo-gallery-detail.component';
import { LeadersComponent } from './leaders/leaders.component';
import { ApartmentsComponent } from './apartments/apartments.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { SpaceComponent } from './components/space/space.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DesignComponent } from './design/design.component';
import { SideNavService } from './services/SideNav/side-nav.service';
import { HttpClientModule } from '@angular/common/http';
import { FacilityCardComponent } from './components/facility-card/facility-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { PrivacyComponent } from './privacy/privacy.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import {MatIconModule} from '@angular/material/icon';
import { AppHorizontalScrollDirective } from './directives/app-horizontal-scroll.directive';
import { AboutUsComponent } from './about-us/about-us.component';
import { ApartmentDetailsComponent } from './dialogs/apartment-details/apartment-details.component';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    NavbarComponent,
    FooterComponent,
    ConstructionProgressComponent,
    GalleryComponent,
    LocationComponent,
    TopHeaderComponent,
    BenefitsComponent,
    HowToBuyComponent,
    FavoriteItemComponent,
    MakeYourChoiceComponent,
    NewsComponent,
    NewItemComponent,
    PhotoGalleryDetailComponent,
    LeadersComponent,
    ApartmentsComponent,
    SpaceComponent,
    ContactUsComponent,
    DesignComponent,
    FacilityCardComponent,
    PrivacyComponent,
    NewsDetailsComponent,
    AppHorizontalScrollDirective,
    AboutUsComponent,
    ApartmentDetailsComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    BrowserModule,
    MatStepperModule,
    MatSliderModule,
    MatMenuModule,
    MatSelectModule,
    NgxSliderModule,
    MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatDialogModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [SideNavService],
  bootstrap: [AppComponent]
})
export class AppModule { }
