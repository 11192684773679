<div class="detail">
  <app-top-header title="{{details.name}}"></app-top-header>

  <section class="mt-3">
    <div class="content">
      <img [src]="details.picture" width="100%" alt="image" />
      <!-- <div class="container">
          <p>
            MASTER-PLANNERS HAVE COME TOGETHER TO CREATE THE IDEAL USE OF GREEN
            VIEWS WITHIN THE ECO-CLUSTER CONCEPT WITHIN A 19-ACHER BOUTIQUE
            DEVELOPMENT, A HOME TO OVER 500 RESIDENTIAL UNITS. APPRECIATE A LIVING
            EXPERIENCE WITH AN EMPHASIS ON GREEN GARDEN AND PEDESTRIAN ZONES TO
            ALLOW RESIDENTS TO LEAD A TRANQUIL LIFE THAT IS RESTRICTED, RESERVED AND
            SAFE.
          </p>
      </div> -->
    </div>
  </section>
</div>
