import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SideNavService } from 'src/app/services/SideNav/side-nav.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild('about', {static: true}) sidenav: MatSidenav;
  constructor(
    private route:Router,
    private sidenavService: SideNavService
  ) { }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnInit(): void {
  }

  linkTo(nav:any,link:any){
    nav.toggle();
    this.route.navigate([`${link}`])
  }

}
