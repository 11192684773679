<div class="construction">
  <div class="header">
    <h1>BUY</h1>
  </div>

  <div class="type">
    <div class="row justify-content-center mx-0">
      <div class="item" (click)="setType('cash')" [ngClass]="{'active': type=='cash'}">CASH</div>
      <!-- <div class="item" (click)="setType('installment')" [ngClass]="{'active': type=='installment'}">INSTALLMENTS</div> -->
    </div>

    <ng-container *ngIf="type=='cash'">
      <div class="cash my-5">
        <h1>{{data?.phone_number}}</h1>
      </div>
    </ng-container>

  </div>

  <ng-container *ngIf="type=='installment'">
    <div class="container">
      <div class="row justify-content-center py-5">
        <div class="col-md-3 m-2">
          <div class="d-flex flex-column">
            <div class="title">UNIT PRICE</div>
            <div class="value">{{unit_gte|number}} - {{ unit_lte|number }} EGP</div>
          </div>
          <ngx-slider [(value)]="unit_gte" [(highValue)]="unit_lte" [options]="optionsUnit"
            (userChangeEnd)="unitsChange($event)"></ngx-slider>
        </div>

        <div class="col-md-3 m-2">
          <div class="d-flex flex-column">
            <div class="title">DOWN PAYMENT</div>
            <div class="value">{{down_gte|number}} - {{ down_lte|number }} EGP</div>
          </div>
          <ngx-slider [(value)]="down_gte" [(highValue)]="down_lte" [options]="downUnit" (userChangeEnd)="downChange($event)"></ngx-slider>
        </div>
        <div class="col-md-3 m-2">
          <div class="d-flex flex-column">
            <div class="title">NO OF YEARS</div>
            <div class="value">{{year_gte}} - {{ year_lte }} YEARS</div>
          </div>
          <ngx-slider [(value)]="year_gte" [(highValue)]="year_lte" [options]="yearsUnit"
            (userChangeEnd)="yearsChange($event)"></ngx-slider>
        </div>
      </div>
    </div>

    <div class="third">
      <div class="container">
        <div class="row justify-content-center py-5">
          <!-- <div class="col-md-4">
            <div class="label">
              INTEREST RATE
            </div>
            <div class="precent">
              5%
            </div>
          </div> -->
          <div class="col-md-8 text-center">
            <div class="label">
              MONTHLY PAYMENT BETWEEN
            </div>
            <div class="precent">
              {{min_monthly|number}} - {{max_monthly|number}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-make-your-choice></app-make-your-choice>

</div>