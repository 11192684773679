<div class="news">
  <app-top-header title="{{data?.title}}"></app-top-header>

  <app-space></app-space>

  <div class="container">
    <div class="row">
      <div class="col-md-4" *ngFor="let item of data?.news">
        <app-new-item [item]="item"></app-new-item>
      </div>
      <!-- <div class="col-md-4">
        <app-new-item></app-new-item>
      </div>
      <div class="col-md-4">
        <app-new-item></app-new-item>
      </div>
      <div class="col-md-4">
        <app-new-item></app-new-item>
      </div>
      <div class="col-md-4">
        <app-new-item></app-new-item>
      </div>
      <div class="col-md-4">
        <app-new-item></app-new-item>
      </div> -->
    </div>
  </div>

  <app-make-your-choice></app-make-your-choice>
</div>
