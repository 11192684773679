<div class="location">
  <app-top-header title="DEVELOPMENTS"></app-top-header>

  <section class="py-3">
    <div class="first-dev">
      <img [src]="data?.cover" width="100%" alt="image" />
      <div class="container">
        <h1>{{data?.name}}</h1>
      </div>
    </div>

    <div class="sec-div">
      <div class="container mr-0">
        <app-space></app-space>
        <p>{{data?.description}}</p>
        <app-space></app-space>

        <div *ngFor="let project of data?.projects">
          <img [src]="project.cover" class="mb-5" width="300" alt="" />

          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of project.slider_pictures">
              <div class="item">
                <img src="{{item.picture}}" height="350" alt="{{item.name}}" style="object-fit: contain;" />
                <div class="title">{{item.name}}</div>
                <div class="desc">{{item.description}}</div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>


       <app-space></app-space>

      </div>
    </div>

    <app-make-your-choice></app-make-your-choice>
  </section>
</div>
