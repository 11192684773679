import { Component, OnInit } from '@angular/core';
import { HomeService } from '../services/home/home.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  data:any = {}

  constructor(
    private _HomeService:HomeService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this._HomeService.get('news').subscribe(res=>{
      this.data = res
    },err=>{
      console.log(err);
    })
  }

}
